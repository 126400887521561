import Image from "next/image";
import { useTranslation } from "next-i18next";

import OutlinedBtn from "../../components/Buttons/OutlinedButton";
import CustomHead from "../../components/CustomHead";
import MaxWidth from "../../components/MaxWidth";
import PageDescription from "../../components/PageDescription";
import PageWrapper from "../../components/PageWrapper";

import styles from "./404.module.scss";

import Img from "../../public/imgs/owl.png";
import GridContainer from "../../components/GridContainer/GridContainer";

export default function NotFoundPage() {
  const { t } = useTranslation("common");

  const registerEmailCopy = () => {
    (window as any).ym(
      process.env.NEXT_PUBLIC_YANDEX_METRIC_ID,
      "reachGoal",
      "email_copy"
    );
  };

  return (
    <PageWrapper>
      <CustomHead title={"404"} />
      <PageDescription
        title="404"
        description={
          <span>
            {t("page_not_found")}
            <a
              href="mailto:info@tevian.ai"
              className={styles["link"]}
              onCopy={registerEmailCopy}
            >
              info@tevian.ai
            </a>
          </span>
        }
      />
      <MaxWidth>
        <GridContainer>
          <OutlinedBtn
            title={t("to_the_main_page")}
            href="/"
            addclasses={[styles["btn"]]}
          />
          <Image src={Img} alt="" className={styles["img"]} />
        </GridContainer>
      </MaxWidth>
    </PageWrapper>
  );
}
