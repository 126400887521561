import { FC, HTMLProps, ReactNode } from "react";
import classNames from "classnames";

import GridContainer from "../GridContainer/GridContainer";
import MaxWidth from "../MaxWidth/MaxWidth";

import styles from "./PageDescription.module.scss";

export type Props = {
  title: string;
  description: string | ReactNode;
  addclasses?: string[];
} & HTMLProps<HTMLDivElement>;

const PageDescription: FC<Props> = (props) => {
  const { title, description, addclasses } = props;
  const className = classNames(
    [styles["container"], addclasses && [...addclasses]],
    {}
  );

  return (
    <MaxWidth>
      <GridContainer addclasses={[className]}>
        <h2 className={styles["title"]}>{title}</h2>
        <h1 className={styles["desc"]}>{description}</h1>
      </GridContainer>
    </MaxWidth>
  );
};

export default PageDescription;
