import { serverSideTranslations } from "next-i18next/serverSideTranslations";

export { default } from "./404";

export async function getStaticProps({ locale }: { locale: any }) {
  const changedLocale = locale === "de" ? null : locale;
  return {
    props: {
      ...(await serverSideTranslations(changedLocale || "en", [
        "products",
        "common",
        "mainPage",
        "solutions",
        "map",
        "meta",
      ])),
    },
  };
}
